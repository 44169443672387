<template>
  <ShipmentsBrowse />
</template>

<script>
import ShipmentsBrowse from "@/components/shipments/ShipmentsBrowse";
export default {
  name: "BrowseShipments",
  components: { ShipmentsBrowse }
};
</script>

<style scoped></style>
