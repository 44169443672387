<template>
  <v-container>
    <v-stepper
      alt-labels
      class="mt-12 d-none d-md-block"
      :value="currentStatusId"
      id="md-tracker"
    >
      <v-stepper-header v-if="this.steps">
        <template v-for="(step, key) in steps">
          <v-stepper-step
            :step="step.id"
            :key="key"
            :complete="step.id < currentStatusId"
          >
            {{ step.title }}
          </v-stepper-step>
          <v-divider
            v-if="key !== steps.length - 1"
            :key="key + step"
          ></v-divider>
        </template>
      </v-stepper-header>
    </v-stepper>
    <v-stepper
      alt-labels
      vertical
      class="d-block d-md-none"
      :value="currentStatusId"
      id="sm-tracker"
    >
      <template v-for="(step, key) in steps">
        <v-stepper-step
          :step="step.id"
          :key="key"
          :complete="step.id < currentStatusId"
        >
          {{ step.title }}
        </v-stepper-step>
        <v-divider
          v-if="key !== steps.length - 1"
          :key="key + step"
        ></v-divider>
      </template>
    </v-stepper>
    <v-row class="mt-2">
      <v-col>
        <v-btn color="red" @click="close" dark>Exit</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "StepIndicator",
  props: ["steps", "currentStatusId"],
  methods: {
    close() {
      this.$emit("close-dialog");
    }
  }
};
</script>

<style scoped>
#md-tracker .v-divider {
  border-width: medium;
}
.v-stepper__step--complete + hr {
  background-color: #006940;
}
#md-tracker .v-stepper__step {
  font-size: 12px !important;
}
</style>
