<template>
  <v-card>
    <v-card-title>Shipping Status</v-card-title>
    <v-card-subtitle>Shipment #{{ value.id }}</v-card-subtitle>
    <v-container>
      <StepIndicator
        :steps="shipment_statuses"
        :currentStatusId="getCurrentStatus(value.common_status)"
        v-on:close-dialog="() => close()"
      />
    </v-container>
  </v-card>
</template>
<script>
import StepIndicator from "@/components/indicators/StepIndicator";

export default {
  name: "ShippingStatus",
  components: { StepIndicator },
  props: ["value"],
  data() {
    return {
      current_status_id: 1,
      shipment_statuses: [
        {
          id: 1,
          title: "Pending Shipment"
        },
        {
          id: 2,
          title: "In Transit"
        },
        {
          id: 3,
          title: "Received"
        }
      ]
    };
  },
  methods: {
    getCurrentStatus(status) {
      let current_status = this.shipment_statuses.find(o => o.title === status);
      if (current_status) {
        return current_status.id;
      }
    },
    close() {
      this.$emit("close-dialog");
    }
  }
};
</script>
