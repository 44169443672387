<template>
  <v-card>
    <div v-if="value.received === false">
      <v-btn
        v-if="
          value.date_shipped === null &&
            ((value.easypost_shipment_id !== '' && value.tracking_number) ||
              value.shipment_method_id)
        "
        @click="updateShippedStatus(1)"
        class="float-right ma-3"
        color="primary"
      >
        Mark as Shipped
      </v-btn>
      <v-btn
        v-else-if="
          value.date_shipped !== null &&
            ((value.easypost_shipment_id !== '' && value.tracking_number) ||
              value.shipment_method_id)
        "
        @click="updateShippedStatus(0)"
        class="float-right ma-3"
        color="warning"
      >
        Un-Mark as Shipped
      </v-btn>
    </div>
    <v-card-title>Manage Shipment </v-card-title>
    <v-card-subtitle>Shipment #{{ value.id }}</v-card-subtitle>
    <v-tabs grow>
      <v-tab
        href="#online"
        v-if="
          (value.shipment_method_id === null &&
            value.easypost_shipment_id === '') ||
            value.easypost_shipment_id !== ''
        "
      >
        Online Shipment
      </v-tab>
      <v-tab-item value="online">
        <v-container v-if="error.status">
          <v-card-text class="text-h5 text-center error--text">
            {{ error.message }}
          </v-card-text>
          <v-btn @click="close" text>
            Exit
          </v-btn>
        </v-container>
        <v-container v-else-if="shipping_label !== false">
          <v-card-actions>
            <v-btn color="red" @click="close" dark>Exit</v-btn>
            <v-spacer class="text-center"></v-spacer>
            <a
              v-if="shipping_label.label_pdf_url.length"
              :href="shipping_label.label_pdf_url"
              target="__blank"
              class="mr-2"
            >
              <v-btn color="primary">Print PDF</v-btn>
            </a>
            <a :href="shipping_label.label_url" target="__blank">
              <v-btn color="primary">Print Image</v-btn>
            </a>
          </v-card-actions>
          <v-img :src="shipping_label.label_url">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-container>
        <v-container v-else>
          <v-stepper v-model="step" vertical>
            <v-stepper-step :complete="step > 1" step="1">
              Shipment Details
              <small v-if="step < 2" class="pt-1">Set package size</small>
              <v-subheader v-else>
                Size: {{ size.height }} in x {{ size.width }} in x
                {{ size.length }} in @ {{ size.weight }} Oz
              </v-subheader>
            </v-stepper-step>

            <v-stepper-content step="1">
              <template v-if="!inner_loading">
                <v-container>
                  <v-form ref="size">
                    <v-text-field
                      v-model="size.weight"
                      @focusout="roundSizes"
                      type="number"
                      step="0.1"
                      label="Weight"
                      :rules="required"
                    >
                      <span slot="append">Ounces</span>
                    </v-text-field>
                    <v-text-field
                      v-model="size.height"
                      @focusout="roundSizes"
                      type="number"
                      step="0.1"
                      label="Height"
                      :rules="required"
                    >
                      <span slot="append">Inches</span>
                    </v-text-field>
                    <v-text-field
                      v-model="size.width"
                      @focusout="roundSizes"
                      type="number"
                      step="0.1"
                      label="Width"
                      :rules="required"
                    >
                      <span slot="append">Inches</span>
                    </v-text-field>
                    <v-text-field
                      v-model="size.length"
                      @focusout="roundSizes"
                      type="number"
                      step="0.1"
                      label="Length"
                      :rules="required"
                    >
                      <span slot="append">Inches</span>
                    </v-text-field>
                  </v-form>
                </v-container>
                <v-btn color="primary" @click="loadShipment">
                  Confirm Size
                </v-btn>
                <v-btn @click="close" text>
                  Exit
                </v-btn>
              </template>
              <LoadingBar v-else />
            </v-stepper-content>

            <v-stepper-step :complete="step > 2" step="2">
              Shipping Service
              <small class="pt-1">Choose a service/rate</small>
            </v-stepper-step>

            <v-stepper-content step="2">
              <template v-if="!inner_loading">
                <template>
                  <v-container fluid>
                    <v-radio-group v-model="rate_option" class="mt-0 pt-0">
                      <template v-for="(rate_group, key) in rates">
                        <div :key="key">
                          <p>{{ key }}</p>
                          <v-radio
                            v-for="option in rate_group"
                            :key="option.id"
                            :value="option"
                          >
                            <template v-slot:label>
                              <div>
                                {{ option.service }} {{ getRate(option) }}
                              </div>
                            </template>
                          </v-radio>
                        </div>
                      </template>
                    </v-radio-group>
                  </v-container>
                </template>
                <v-btn
                  color="primary"
                  :disabled="rate_option === null"
                  @click="selectService"
                >
                  Confirm Service/Rate
                </v-btn>
                <v-btn @click="close" text>
                  Exit
                </v-btn>
              </template>
              <LoadingBar v-else />
            </v-stepper-content>
          </v-stepper>
        </v-container>
      </v-tab-item>

      <v-tab href="#manual" v-if="value.easypost_shipment_id === ''">
        Self-Ship Shipment
      </v-tab>
      <v-tab-item value="manual">
        <v-container>
          <v-stepper v-model="step" vertical>
            <v-stepper-step
              :complete="step > 1"
              step="1"
              v-if="value.date_shipped === null"
            >
              Choose Shipping Service
              <small v-if="step < 2" class="pt-1">Self-Ship Shipment</small>
            </v-stepper-step>

            <v-stepper-content step="1">
              <template v-if="!inner_loading">
                <v-container>
                  <v-form
                    ref="shipmentForm"
                    :disabled="isDisabled"
                    @submit.prevent="saveShipment"
                  >
                    <v-select
                      v-model="value.shipment_method_id"
                      :rules="required"
                      :items="this.shipment_methods"
                      item-value="id"
                      item-text="title"
                      label="Shipping Service"
                      no-data-text="No Shipping Services Available"
                    ></v-select>
                    <template v-if="displayShippingFields">
                      <v-textarea
                        v-if="displayOtherField"
                        label="Shipping Service (Other)"
                        :rules="required"
                        v-model="value.shipment_method_other"
                      ></v-textarea>
                      <v-select
                        v-model="value.shipment_method_type"
                        :items="type_service"
                        label="Type of Service"
                        :clearable="true"
                      ></v-select>
                      <v-text-field
                        label="Tracking Number"
                        v-model="value.tracking_number"
                      ></v-text-field>
                    </template>
                    <template v-if="isDropBox">
                      <v-select
                        v-model="dropBoxCountryId"
                        :items="dropBoxCountries"
                        item-text="country_name"
                        item-value="country_id"
                        label="Drop Box Country"
                        :rules="required"
                        :loading="dropbox_loading && dropBoxCountryId === null"
                      />
                      <v-select
                        v-if="dropBoxCountryId && dropBoxStates.length > 0"
                        v-model="dropBoxStateId"
                        :items="dropBoxStates"
                        item-text="title"
                        item-value="id"
                        label="Drop Box State/Province"
                        :rules="required"
                        :loading="dropbox_loading"
                      />
                      <v-select
                        v-if="showDropBoxes"
                        v-model="value.drop_box_location_id"
                        :items="dropBoxLocations"
                        item-text="title"
                        item-value="id"
                        label="Drop Box Location"
                        :rules="required"
                        :loading="dropbox_loading"
                      />
                      <DatePickerInput
                        :default-value="value.drop_box_date"
                        :datePickerConfig="{
                          label: 'Drop Off Date',
                          field: 'drop_off_date'
                        }"
                        @updateDateField="
                          (field, val) => {
                            value.drop_box_date = val;
                          }
                        "
                        :rules="required"
                        :clearable="true"
                      ></DatePickerInput>
                      <v-dialog
                        ref="dialog"
                        v-model="timeModal"
                        :return-value.sync="value.drop_box_time"
                        persistent
                        width="350px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="value.drop_box_time"
                            label="Drop Off Time"
                            append-icon="mdi-clock-outline"
                            readonly
                            :rules="required"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="timeModal"
                          v-model="value.drop_box_time"
                          full-width
                          use-seconds
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="timeModal = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save(value.drop_box_time)"
                          >
                            OK
                          </v-btn>
                        </v-time-picker>
                      </v-dialog>
                    </template>
                    <div v-if="value.date_shipped === null" class="mt-2">
                      <v-btn type="submit" color="primary">
                        Save Shipment Details
                      </v-btn>
                      <v-btn @click="close" text>
                        Exit
                      </v-btn>
                    </div>
                  </v-form>
                </v-container>
              </template>
              <LoadingBar v-else />
            </v-stepper-content>
          </v-stepper>
        </v-container>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import LoadingBar from "@/components/loaders/LoadingBar";
import DatePickerInput from "@/components/formElements/DatePickerInput";
import { mapActions, mapGetters, mapState } from "vuex";
import groupBy from "lodash.groupby";
import dayjs from "dayjs";
import { mapFields } from "vuex-map-fields";

export default {
  name: "ShippingLabel",
  components: { DatePickerInput, LoadingBar },
  props: ["value", "shipping_methods"],
  data() {
    return {
      shipping_label: false,
      inner_loading: false,
      dropbox_loading: false,
      timeModal: false,
      step: 1,
      ep_item: false,
      rate_option: null,
      type_service: ["Ground", "Second Day", "Next Day"],
      size: {
        width: null,
        height: null,
        length: null,
        weight: null
      },
      details: {
        source: null
      },
      currency_formatters: {
        USD: new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD"
        })
      },
      required: [v => !!v || "This field is required"],
      error: {
        status: false,
        message: ""
      }
    };
  },
  watch: {
    value(val, val2) {
      if (!val2 || val.id !== val2.id) {
        this.init();
      }
    },
    isDropBox: {
      handler: async function(isIt) {
        if (!isIt || this.dropBoxCountries.length > 0) return;
        this.dropbox_loading = true;
        await this.getDropBoxCountries();
        this.dropbox_loading = false;
      },
      immediate: true
    },
    async dropBoxCountryId(countryId, oldId) {
      if (!countryId || countryId === oldId) return;
      if (!this.dropbox_loading) this.dropBoxStateId = null;
      this.dropbox_loading = true;
      await this.getDropBoxLocations();
      this.dropbox_loading = false;
    }
  },
  computed: {
    ...mapGetters("baseData", ["shipment_methods", "filterById"]),
    ...mapState("shipments", ["dropBoxCountries", "dropBoxLocation"]),
    ...mapGetters("shipments", ["dropBoxStates", "dropBoxLocations"]),
    ...mapFields("shipments", ["dropBoxCountryId", "dropBoxStateId"]),
    isDisabled() {
      return this.value.date_shipped !== null;
    },
    rates() {
      let rates = this.ep_item && this.ep_item.rates ? this.ep_item.rates : [];
      if (!rates.length) return rates;
      return groupBy(rates, "carrier");
    },
    displayOtherField() {
      return (
        this.getShipmentMethodIdOnTitle("Other") ===
        this.value.shipment_method_id
      );
    },
    isDropAtLab() {
      return (
        this.getShipmentMethodIdOnTitle("Drop at lab") ===
        this.value.shipment_method_id
      );
    },
    isDropBox() {
      return (
        this.getShipmentMethodIdOnTitle("Drop Box") ===
        this.value.shipment_method_id
      );
    },
    showDropBoxes() {
      return (
        this.dropBoxCountryId &&
        (this.dropBoxStates.length === 0 || this.dropBoxStateId)
      );
    },
    displayShippingFields() {
      return !this.isDropAtLab && !this.isDropBox;
    }
  },
  methods: {
    ...mapActions(["notify"]),
    ...mapActions("shipments", [
      "epGetShipment",
      "epUpdateShipment",
      "editShipment",
      "getDropBoxCountries",
      "getDropBoxLocations",
      "getDropBoxLocation"
    ]),
    setError(message = "") {
      this.error.status = !!message.length;
      this.error.message = message;
    },
    setEasyPostItem(item) {
      this.ep_item = item;
      this.$emit("refresh", true);
      if (this.ep_item.parcel && this.ep_item.parcel.weight) {
        ["weight", "height", "width", "length"].map(item => {
          this.size[item] = this.ep_item.parcel[item];
        });
      }
      this.processShipmentResult();
    },
    roundSizes() {
      // Round Each size input to the nearest single decimal point
      for (let i in this.size) {
        if (parseFloat(this.size[i]) > 0.01) {
          this.size[i] = Math.round(this.size[i] * 10) / 10;
        }
      }
    },
    getRate(rate) {
      if (this.currency_formatters[rate.currency] === undefined) {
        this.currency_formatters[rate.currency] = new Intl.NumberFormat(
          "en-US",
          {
            style: "currency",
            currency: rate.currency
          }
        );
      }
      return this.currency_formatters[rate.currency].format(
        parseFloat(rate.rate)
      );
    },
    init() {
      this.setError();
      this.shipping_label = false;
      this.step = 1;
      this.ep_item = false;
      this.inner_loading = true;
      this.dropBoxCountryId = null;
      this.dropBoxStateId = null;

      // If this shipment already has an easypost ID
      if (
        this.value.easypost_shipment_id &&
        this.value.easypost_shipment_id.length
      ) {
        this.loadShipment(true);
      } else {
        if (
          !this.value.lab.easypost_address_id ||
          !this.value.lab.easypost_address_id.length
        ) {
          this.setError("Pre-paid labels have not been set up for this Lab.");
          return;
        }
        this.inner_loading = false;
      }
      if (this.value.drop_box_location_id) {
        this.dropbox_loading = true;
        this.getDropBoxLocation(this.value.drop_box_location_id).then(() => {
          this.$nextTick(() => {
            this.dropBoxCountryId = this.dropBoxLocation.country_id;
          });
          this.$nextTick(() => {
            this.dropBoxStateId = this.dropBoxLocation.state_id;
          });
        });
      }
    },
    processShipmentResult() {
      if (
        this.ep_item.postage_label &&
        this.ep_item.postage_label.label_url &&
        this.ep_item.postage_label.label_url.length
      ) {
        this.shipping_label = this.ep_item.postage_label;
      } else if (this.ep_item.parcel) {
        if (this.ep_item.messages.length > 0) {
          this.error.message = this.ep_item.messages[0].message;
          this.error.status = true;
        }
        this.step = 2;
      }
    },
    loadShipment(skipValidate = false) {
      this.setError();
      this.roundSizes();
      if (skipValidate || this.$refs.size.validate()) {
        this.inner_loading = true;
        return this.epGetShipment({
          shipment: this.value,
          params: this.size
        }).then(data => {
          if (data === false) {
            this.setError("Request Error");
          } else {
            this.setEasyPostItem(data);
          }
          this.inner_loading = false;
        });
      }
    },
    selectService() {
      this.inner_loading = true;
      this.epUpdateShipment({
        shipment: this.value,
        data: {
          rate_id: this.rate_option.id
        }
      }).then(data => {
        this.setEasyPostItem(data);
        this.inner_loading = false;
      });
    },
    nextStep() {
      this.step = this.step + 1;
    },
    close() {
      this.setError();
      this.$emit("close-dialog");
    },
    getShipmentMethodIdOnTitle(title) {
      const shipMethod = this.shipment_methods.find(
        method => method.title === title
      );
      return shipMethod ? shipMethod.id : false;
    },
    saveShipment() {
      if (!this.$refs.shipmentForm.validate()) return false;
      let promise = this.editShipment(this.value);
      promise.then(() => {
        this.notify({
          message: "Shipment #" + this.value.id + " has been updated."
        });
        this.$emit("refresh", true);
        this.close();
      });
    },
    updateShippedStatus(shipped_status) {
      if (shipped_status) {
        this.value.date_shipped = dayjs().format("YYYY-MM-DD");
      } else {
        this.value.date_shipped = null;
      }
      this.saveShipment();
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style scoped></style>
